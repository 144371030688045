import React from 'react'
import Section1 from './section1'
import Section2 from './section2'
import Section3 from './section3'
import { ContractInfo, MyStakingContractInfo } from '../Staking/controller'
import styled from 'styled-components'

interface DetailViewProps {
  contractInfo: ContractInfo | undefined
  myStakingContractInfo: MyStakingContractInfo | undefined
  stake: (amount: number) => Promise<void>
  unstake: () => Promise<void>
}

const DetailView = (props: DetailViewProps) => {
  const { contractInfo, myStakingContractInfo, stake, unstake } = props
  return (
    <>
      {contractInfo && myStakingContractInfo && (
        <StakingDetailCardContainer>
          <Section1 contractInfo={contractInfo} staking={stake} myStakingContractInfo={myStakingContractInfo} />
          <Section2 contractInfo={contractInfo} unstaking={unstake} myStakingContractInfo={myStakingContractInfo} />
          <Section3 contractInfo={contractInfo} />
        </StakingDetailCardContainer>
      )}
    </>
  )
}

export default DetailView

const StakingDetailCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
