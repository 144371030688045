import React from 'react'
import styled from 'styled-components'

interface ButtonProps {
  children: React.ReactNode
  type: 'big' | 'small' | 'navsmall' | 'detail' | 'double' | 'unfilled'
  onClick?: () => void
  active?: boolean
  style?: React.CSSProperties
}

interface BaseButtonProps {
  active?: boolean
}

const Button = (props: ButtonProps) => {
  const { children, onClick, active, style } = props
  const handleClick = () => {
    if (active) {
      onClick && onClick()
    }
  }
  switch (props.type) {
    case 'big':
      return (
        <BigButton onClick={handleClick} style={style} active={active}>
          {children}
        </BigButton>
      )
    case 'small':
      return (
        <SmallButton onClick={handleClick} style={style} active={active}>
          {children}
        </SmallButton>
      )
    case 'navsmall':
      return (
        <NavSmallButton onClick={handleClick} style={style} active={active}>
          {children}
        </NavSmallButton>
      )
    case 'detail':
      return (
        <DetailStakeButton onClick={handleClick} style={style} active={active}>
          {children}
        </DetailStakeButton>
      )
    case 'double':
      return (
        <NavDoubleButton onClick={handleClick} style={style} active={active}>
          {children}
        </NavDoubleButton>
      )
    case 'unfilled':
      return (
        <UnfilledButton onClick={handleClick} style={style} active={active}>
          {children}
        </UnfilledButton>
      )
    default:
      return (
        <BaseButton onClick={handleClick} style={style} active={false}>
          {children}
        </BaseButton>
      )
  }
}

const BaseButton = styled.button<BaseButtonProps>`
  border: none;
  border-radius: 20px;
  background-color: ${(props) => (props.active ? props.theme.ThemeColor.brand : props.theme.ThemeColor.inactive)};
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
  outline: none;
  color: white;
`
const BigButton = styled(BaseButton)`
  height: 30px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    height: 30px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    height: 50px;
    border-radius: 30px;
  }
`
const SmallButton = styled(BaseButton)`
  height: 26px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    height: 25px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    height: 40px;
  }
`
const NavSmallButton = styled(BaseButton)`
  height: 26px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    height: 24px;
    padding: 0 20px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    height: 40px;
    padding: 0 20px;
  }
`

const DetailStakeButton = styled(BaseButton)`
  height: 26px;
  background-color: ${(props) => (props.active ? props.theme.ThemeColor.brand : props.theme.ThemeColor.inactive)};
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    height: 26px;
    min-width: 220px;
    max-width: 220px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    height: 24px;
    padding: 0 20px;
    min-width: 170px;
    max-width: 170px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    height: 40px;
    padding: 0 20px;
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const NavDoubleButton = styled(BaseButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  border: 1px solid ${(props) => props.theme.ThemeColor.brand};
  border-radius: 20px;
  margin: 0;
  padding: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    & > :first-child {
      padding: 0 10px 0 10px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    & > :first-child {
      padding: 0 30px 0 30px;
    }
  }
`

const UnfilledButton = styled(BaseButton)`
  background-color: black;
  border: 1px solid ${(props) => props.theme.ThemeColor.brand};
  border-radius: 20px;
  margin: 0;
  padding: 0;
`

export default Button

// mobile big 200/30 small 200/26 navsmall 107/26
// tablet big 200/30 small 170/25 navsmall 110/24
// desktop big 350/50 small 182/40 navsmall 200/40
