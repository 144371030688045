import React, { useEffect, useState } from 'react'
import MainView from '..'
import { GetPoolData } from '../../../service/pool'
import { Contract, Pool } from '../../../classes'
import Web3 from 'web3'
import { getContract, getContractInfo } from '../../../service/web3'
import { GetTotalData } from 'src/service/total'

const POOL_V3_ABI = require('../../../service/web3/poolv3.abi.json')

const StakeController = () => {
  const [totalValuePrice, setTotalValuePrice] = useState<number>(0)
  const [totalValueCount, setTotalValueCount] = useState<number>(0)
  const [totalSentRewardPrice, setTotalRevenuePrice] = useState<number>(0)
  const [totalSentReward, setTotalRevenueCount] = useState<number>(0)

  useEffect(() => {
    const exec = async () => {
      try {
        const [pools, supply] = await Promise.all([GetPoolData(), GetTotalData()])
        // TODO: 타입정의
        if (pools.length > 0) {
          const resultPool: Pool[] = pools.map((pool: any) => {
            return new Pool(pool)
          })
          const usd = resultPool[0].rewardToken.tokenPrices[0].price
          const tvl = parseFloat(supply.tvl)
          const trl = parseFloat(supply.trl)
          const totalValuePrice = tvl * usd
          const totalSentRewardPrice = trl * usd
          setTotalValuePrice(totalValuePrice)
          setTotalValueCount(tvl)
          setTotalRevenuePrice(totalSentRewardPrice)
          setTotalRevenueCount(trl)
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          return alert(e.message)
        }
      }
    }

    exec()
  }, [])

  return (
    <MainView
      title={'Stake'}
      totalValuePrice={totalValuePrice}
      totalValueCount={totalValueCount}
      totalSentRewardPrice={totalSentRewardPrice}
      totalSentReward={totalSentReward}
    />
  )
}

export default StakeController
