import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'

interface GraphProps {
  style?: React.CSSProperties
  startedAt: string
  finishedAt: string
}

const calculateBarWidth = (start: number, end: number, now: number): number => {
  const totalDuration = end - start
  const currentDuration = now - start

  if (end < now) return 100
  if (start > now) return 0
  return (currentDuration / totalDuration) * 100
}

const Graph = memo((props: GraphProps) => {
  const { style, startedAt, finishedAt } = props
  const start = new Date(startedAt).getTime()
  const end = new Date(finishedAt).getTime()
  const now = new Date().getTime()

  const [barWidth, setBarWidth] = useState(calculateBarWidth(start, end, now))

  useEffect(() => {
    const interval = setInterval(() => {
      setBarWidth(calculateBarWidth(start, end, new Date().getTime()))
    }, 1000)

    return () => clearInterval(interval)
  }, [start, end])

  return (
    <StyledGraph.Container style={style}>
      <StyledGraph.LongBar>
        <StyledGraph.ShortBar width={barWidth} />
      </StyledGraph.LongBar>
    </StyledGraph.Container>
  )
})

export default Graph

const StyledGraph = {
  Container: styled.div`
    width: 100%;
    height: 3px;
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      height: 5px;
    }
  `,
  LongBar: styled.div`
    width: 100%;
    height: 100%;
    background-color: white;
    position: relative;
    border-radius: 5px;
  `,
  ShortBar: styled.div<{ width: number }>`
    position: absolute;
    width: ${({ width }) => `${width}%`};
    background-color: ${(props) => props.theme.ThemeColor.brand};
    height: 100%;
    border-radius: 5px;
  `
}
