import axios from 'axios';

const instance = axios.create({
	baseURL: 'https://api.mmvg.io',
	timeout: 10000,
});

instance.interceptors.request.use((config) => {
	return config;
});

instance.interceptors.response.use((response) => {
	return response.data;
});

export default instance;
