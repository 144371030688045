import Web3 from 'web3'
import { format } from 'date-fns'

class Wallet {
  web3!: Web3
  account!: string
  constructor(props: { web3: Web3; account: string }) {
    this.web3 = props.web3
    this.account = props.account
  }
}
class Network {
  id!: number
  chainId!: number
  name!: string
  rpc!: string
  constructor(props: { id: number; chainId: number; name: string; rpc: string }) {
    this.id = props.id
    this.chainId = props.chainId
    this.name = props.name
    this.rpc = props.rpc
  }
}
class Token {
  id!: number
  name!: string
  symbol!: string
  address!: string
  decimals!: number
  tokenPrices!: { id: number; currency: string; price: number }[]
  iconUrl!: string
}
class Pool {
  id!: number
  name!: string
  address!: string
  network!: Network
  stakingToken!: Token
  rewardToken!: Token
  isAbleStaking!: boolean
  isAbleUnstaking!: boolean
  finishedAt!: string
  startedAt!: string
  period!: string
  nftlist!: string[]
  nftImgUrl!: string
  unstakingEnabledAt: string

  constructor(props: {
    id: number
    name: string
    address: string
    network: Network
    stakingToken: Token
    rewardToken: Token
    isAbleStaking: boolean
    isAbleUnstaking: boolean
    finishedAt: string
    startedAt: string
    nftlist: string[]
    nftImgUrl: string
    unstakingEnabledAt: string
  }) {
    this.id = props.id
    this.name = props.name
    this.address = props.address
    this.network = props.network
    this.stakingToken = props.stakingToken
    this.rewardToken = props.rewardToken
    this.isAbleStaking = props.isAbleStaking
    this.isAbleUnstaking = props.isAbleUnstaking
    this.finishedAt = props.finishedAt
    this.startedAt = props.startedAt
    this.period = `${format(new Date(props.startedAt), 'dd/MM/yyyy HH:mm:ss')} ~ ${format(
      new Date(props.finishedAt),
      'dd/MM/yyyy HH:mm:ss'
    )}`
    this.nftlist = props.nftlist
    this.nftImgUrl = props.nftImgUrl ?? ''
    this.unstakingEnabledAt = props.unstakingEnabledAt
  }
}

class Contract {
  contract!: any
  web3!: Web3
  pool!: Pool
  constructor(props: { contract: any; web3: Web3; pool: Pool }) {
    this.contract = props.contract
    this.web3 = props.web3
    this.pool = props.pool
  }
}

class ContractInfo {
  id!: number
  name!: string
  address!: string
  totalSupply!: number
  totalSupplyPrice!: number
  totalReward!: number
  totalRewardPrice!: number
  isLocked!: boolean
  apy: number
  icon: string
  symbol: string
  network: string
  constructor(props: {
    id: number
    name: string
    address: string
    totalSupply: number
    totalSupplyPrice: number
    totalReward: number
    totalRewardPrice: number
    isLocked: boolean
    apy: number
    icon: string
    symbol: string
    network: string
  }) {
    this.id = props.id
    this.name = props.name
    this.address = props.address
    this.totalSupply = props.totalSupply
    this.totalSupplyPrice = props.totalSupplyPrice
    this.totalReward = props.totalReward
    this.totalRewardPrice = props.totalRewardPrice
    this.isLocked = props.isLocked
    this.apy = props.apy
    this.icon = props.icon
    this.symbol = props.symbol
    this.network = props.network
  }
}

class MyStakingInfo {
  stakedTokenCount: number
  stakedTokenPrice: number
  unclaimedRewardTokenCount: number
  unclaimedRewardTokenPrice: number

  constructor(props: {
    stakedTokenCount: number
    stakedTokenPrice: number
    unclaimedRewardTokenCount: number
    unclaimedRewardTokenPrice: number
  }) {
    this.stakedTokenCount = props.stakedTokenCount
    this.stakedTokenPrice = props.stakedTokenPrice
    this.unclaimedRewardTokenCount = props.unclaimedRewardTokenCount
    this.unclaimedRewardTokenPrice = props.unclaimedRewardTokenPrice
  }
}

export { Wallet, Network, Token, Pool, Contract, ContractInfo, MyStakingInfo }
