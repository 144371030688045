import React from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import { ToLocaleWithFixed, ToMilions } from 'src/utils/number'

interface Section1Props {
  totalValueCount: number
  totalValuePrice: number
  totalSentReward: number
  totalSentRewardPrice: number
}

const Section1 = (props: Section1Props) => {
  const { totalValueCount, totalValuePrice, totalSentReward, totalSentRewardPrice } = props
  return (
    <SectionWrap>
      <ValueWrap>
        <Shared.Text type="light" level={2}>
          Total Value Locked
        </Shared.Text>
        <Shared.Text type="extrabold" level={2} style={{ color: '#f4ad48' }}>
          {ToMilions(totalValueCount)} MMVG
        </Shared.Text>
        <Shared.Text type="regular" level={2}>
          $ {ToLocaleWithFixed(totalValuePrice)}
        </Shared.Text>
      </ValueWrap>
      <ValueWrap>
        <Shared.Text type="light" level={2}>
          Total Revenue Locked
        </Shared.Text>
        <Shared.Text type="extrabold" level={2} style={{ color: '#f4ad48' }}>
          {ToMilions(totalSentReward)} MMVG
        </Shared.Text>
        <Shared.Text type="regular" level={2}>
          $ {ToLocaleWithFixed(totalSentRewardPrice)}
        </Shared.Text>
      </ValueWrap>
    </SectionWrap>
  )
}

const SectionWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 70px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin: 70px 0;
    div:first-child {
      margin-bottom: 50px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
    margin: 100px 0;
    div:first-child {
      margin-bottom: 0;
    }
  }
`
const ValueWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default Section1
