import React from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import MMVG from '../index.json'
import { ContractInfo } from '../Staking/controller'
import { ToLocaleWithFixed, ToMilions } from '../../utils/number'
import { ThemeColor } from '../../theme/color'

interface Section3Props {
  contractInfo: ContractInfo
}

const Section3 = (props: Section3Props) => {
  const { contractInfo } = props

  const shortenAddress = (address: string) => {
    return address.slice(0, 7) + '...' + address.slice(-6)
  }
  return (
    <>
      <SubTitleWrap>
        <Shared.Text type="bold" level={3} style={{ marginRight: 'auto' }}>
          Stake Information
        </Shared.Text>
      </SubTitleWrap>
      <Shared.StakingGrayBox>
        <FlexBetweenWrap>
          <ColumnInner>
            <Shared.Text type="regular" level={1}>
              Network
            </Shared.Text>
            <Shared.Text type="bold" level={1}>
              ERC-20
            </Shared.Text>
          </ColumnInner>
          <ColumnInner>
            <Shared.Text type="regular" level={1}>
              Type
            </Shared.Text>
            <Shared.Text type="bold" level={1}>
              {contractInfo.isLocked ? 'Locked' : 'Flexible'}
            </Shared.Text>
          </ColumnInner>
          <ColumnInner>
            <Shared.Text type="regular" level={1}>
              APY
            </Shared.Text>
            <Shared.Text type="bold" level={1}>
              {ToLocaleWithFixed(contractInfo.apy)} %
            </Shared.Text>
          </ColumnInner>
        </FlexBetweenWrap>
        <ColumnFullInner>
          <Shared.Text type="regular" level={1}>
            Period
          </Shared.Text>
          <Shared.Text type="regular" level={1}>
            {contractInfo.pool.period}
          </Shared.Text>
        </ColumnFullInner>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Shared.Text type="regular" level={1}>
              Contract addresses
            </Shared.Text>
            <div onClick={() => window.open(`https://etherscan.io/address/${contractInfo.address}`)}>
              <Shared.Text
                type="regular"
                level={1}
                style={{ color: ThemeColor.brand, textDecoration: 'underline', cursor: 'pointer' }}
              >
                {shortenAddress(contractInfo.address)}
              </Shared.Text>
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'top', justifyContent: 'space-between', textAlign: 'right' }}>
            <Shared.Text type="regular" level={1}>
              Total Staked
            </Shared.Text>
            <div>
              <Shared.Text type="regular" level={1}>
                {ToMilions(contractInfo.totalSupply)} {contractInfo.pool.stakingToken.symbol}
              </Shared.Text>
              <Shared.Text type="regular" level={1}>
                $ {ToLocaleWithFixed(contractInfo.totalSupplyPrice)}
              </Shared.Text>
            </div>
          </div>
        </div>
      </Shared.StakingGrayBox>
    </>
  )
}

export default Section3

const BoxInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-align: left;
  width: 100%;
`

const ColumnInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ColumnFullInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
`

const SubTitleWrap = styled.div`
  width: 310px;
  text-align: left;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 451px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 556px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const HalfWrap = styled.div`
  display: flex;
  width: 50%;
`

const FlexBetweenWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
`
