import { useState, useEffect } from 'react';
import Web3 from 'web3';

const ConnectMetamask = () => {
	const [web3, setWeb3] = useState<Web3 | null>(null);
	const [account, setAccount] = useState<string | null>(null);

	useEffect(() => {
		// Check if Metamask is installed
		if (typeof (window as any).ethereum !== 'undefined') {
			const web3Instance = new Web3((window as any).ethereum);
			setWeb3(web3Instance);

			// Request account access if needed
			(window as any).ethereum
				.request({ method: 'eth_requestAccounts' })
				.then((accounts: string[]) => {
					setAccount(accounts[0]);
				})
				.catch((error: any) => {
					console.error('Error connecting Metamask:', error);
				});
		} else {
			console.error('Metamask not installed.');
		}
	}, []);

	return (
		<div>
			{web3 ? <h1>Web3 connected</h1> : <h1>Connecting Web3...</h1>}
			{account ? <h1>Connected account: {account}</h1> : null}
		</div>
	);
};

export default ConnectMetamask;
