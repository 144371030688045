import React from 'react'
import styled from 'styled-components'

interface GrayBoxProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

const GrayBox = (props: GrayBoxProps) => {
  const { children, style } = props
  return <StyledGrayBox style={style}>{children}</StyledGrayBox>
}

const StyledGrayBox = styled.div`
  width: 270px;
  background-color: ${(props) => props.theme.ThemeColor.gray};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 205px;
    padding: 15px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 319px;
  }
`

export default GrayBox
