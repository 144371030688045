import React from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import MMVG from '../index.json'
import { ThemeColor } from '../../theme/color'

interface ContentWrapProps {
  content: string
}

const Section3 = () => {
  return (
    <SectionWrap>
      <ContentWrap content="MMVG">
        <Shared.MMVGIcon.Ticker style={{ padding: '20px' }} />
        <TextWrap>
          <Shared.Text type="extrabold" level={3} style={{ color: 'white' }}>
            MMVG
          </Shared.Text>
          <Shared.Text type="regular" level={3}>
            {MMVG.home.MMVG}
          </Shared.Text>
        </TextWrap>
      </ContentWrap>
      <ContentWrap content="NFT">
        <TextWrap2>
          <Shared.Text type="extrabold" level={3} style={{ color: 'white' }}>
            NFT Membership
          </Shared.Text>
          <Shared.Text type="bold" level={2} style={{ color: ThemeColor.brand }}>
            {MMVG.home.NFT_Membership.bold}
          </Shared.Text>
          <Shared.Text type="regular" level={3}>
            {MMVG.home.NFT_Membership.regular}
          </Shared.Text>
        </TextWrap2>
        <Shared.MMVGIcon.NFTCard />
      </ContentWrap>
    </SectionWrap>
  )
}

const SectionWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ContentWrap = styled.div<ContentWrapProps>`
  display: flex;
  margin-bottom: 100px;
  flex-direction: ${(props) => (props.content === 'NFT' ? 'column-reverse' : 'column')};
  align-items: center;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
    margin-bottom: 130px;
  }
`
const TextWrap = styled.div`
  text-align: center;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    text-align: left;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
  }
`

const TextWrap2 = styled.div`
  text-align: center;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    text-align: left;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
  }
`
export default Section3
