import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import StakingController from './pages/Staking/controller'
import MainController from './pages/Main/controller'
import OveriewController from './pages/overview/controller'
import * as Component from './components/Shared'
import DetailController from './pages/Detail/controller'
import FullLayout from './layout/fullLayout'

const ComponentArray = Object.entries(Component)
// const Preview = () => {
// 	return (
// 		<>
// 			{ComponentArray.map(([Name, Component]) => (
// 				<div key={Name}>
// 					<h1 style={{ color: 'blue' }}>{Name}</h1>
// 					<Component />
// 				</div>
// 			))}
// 		</>
// 	);
// };

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <FullLayout>
              <MainController />
            </FullLayout>
          }
        />
        <Route
          path="/staking"
          element={
            <FullLayout>
              <StakingController />
            </FullLayout>
          }
        />
        <Route
          path="/overview"
          element={
            <FullLayout>
              <OveriewController />
            </FullLayout>
          }
        />
        <Route
          path="/staking/:id"
          element={
            <FullLayout>
              <DetailController />
            </FullLayout>
          }
        />
        <Route path={`*`} element={<Navigate to="/" replace />}></Route>
        {/* <Route path="/" element={<h1>hello mmvg</h1>} /> */}
        {/* preview components */}
        {/* <Route path="/preview" element={<Preview />} /> */}
      </Routes>
    </Router>
  )
}

export default App
