import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import Web3 from 'web3'
import { ThemeColor } from '../../theme/color'
import { ContractInfo, MyStakingContractInfo } from '../Staking/controller'
import { handleShareClick } from '../../utils/shareURL'
import { Verified } from '../../components/Shared/MMVGIcon'
import { ToLocaleWithFixed, ToMilions } from '../../utils/number'

interface Section1Props {
  contractInfo: ContractInfo
  myStakingContractInfo: MyStakingContractInfo
  staking: (amount: number) => Promise<void>
}

const AmountInput = (props: {
  amount: string
  maxAmount: number
  setAmount: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { amount, maxAmount, setAmount } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (/^\d*$/g.test(inputValue)) {
      setAmount(inputValue)
    }
  }

  const handleBlur = () => {
    const formattedValue = Number(amount).toLocaleString()
    setAmount(formattedValue)
  }

  const handleFocus = () => {
    const unformattedValue = amount.replace(/,/g, '')
    setAmount(unformattedValue)
  }

  const handleClickMax = () => {
    setAmount(String(maxAmount))
  }

  return (
    <Info.AmountBox>
      <Shared.Text type="regular" level={1}>
        Amount
      </Shared.Text>
      <Info.AmountInput
        placeholder="000,000"
        type="text"
        value={amount}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <Shared.MMVGIcon.LineAmount />
      <div onClick={handleClickMax} style={{ cursor: 'pointer' }}>
        <Shared.Text type="bold" level={1}>
          MAX
        </Shared.Text>
      </div>
    </Info.AmountBox>
  )
}

const Section1 = (props: Section1Props) => {
  const { contractInfo, myStakingContractInfo, staking } = props
  const [amount, setAmount] = useState<string>('')
  const [isConnected, setIsConnected] = useState(false)

  useEffect(() => {
    const checkMetaMaskConnection = async () => {
      if (window.ethereum) {
        const web3 = new Web3(window.ethereum)
        try {
          const accounts = await web3.eth.getAccounts()
          if (accounts.length > 0) {
            setIsConnected(true)
          } else {
            setIsConnected(false)
          }
        } catch (error) {
          setIsConnected(false)
          alert(error)
        }
      } else {
        setIsConnected(false)
      }
    }
    checkMetaMaskConnection()
  }, [])
  return (
    <>
      {/* nft */}
      {contractInfo.pool.nftlist.length > 0 && (
        <Shared.StakingGrayBox style={{}}>
          <div>
            <Shared.Text type="regular" level={2}>
              This stake requires holding NFTs.
            </Shared.Text>
            {isConnected ? (
              <VerifyWrap>
                <Info.Img src={contractInfo.pool.nftImgUrl} />
                {contractInfo.pool.nftImgUrl && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Verified style={{ marginRight: '5px' }} />
                    <Shared.Text type="regular" level={2}>
                      Verified of holding NFTs
                    </Shared.Text>
                  </div>
                )}
              </VerifyWrap>
            ) : (
              <>
                <Shared.Button type="small" style={{ marginTop: 'auto' }} active={!isConnected}>
                  Wallet Connect
                </Shared.Button>
              </>
            )}
          </div>
        </Shared.StakingGrayBox>
      )}
      {/* input */}
      {contractInfo && (
        <Shared.StakingGrayBox>
          <BottomBoxInner>
            <Shared.MMVGIcon.ShareBtn style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={handleShareClick} />
            <Info.Icon src={contractInfo.pool.rewardToken.iconUrl} />
            <Shared.Text type="bold" level={3}>
              {contractInfo.pool.name}
            </Shared.Text>
            <AmountInput amount={amount} setAmount={setAmount} maxAmount={myStakingContractInfo.balance} />
            <Info.FlexRightWrap style={{ marginTop: '10px' }}>
              <Shared.Text type="regular" level={1}>
                Available: {ToLocaleWithFixed(myStakingContractInfo.balance)} {contractInfo.pool.stakingToken.symbol}
              </Shared.Text>
              <Shared.Text type="regular" level={1} style={{ color: ThemeColor.pink }}>
                Minimum: {contractInfo.limit} {contractInfo.pool.stakingToken.symbol}
              </Shared.Text>
            </Info.FlexRightWrap>
            <Info.FlexBetweenWrap>
              <Shared.Text type="regular" level={1}>
                APY
              </Shared.Text>
              <Shared.Text type="bold" level={2} style={{ color: ThemeColor.brand }}>
                + {ToLocaleWithFixed(contractInfo.apy)} %
              </Shared.Text>
            </Info.FlexBetweenWrap>
            <Info.FlexBetweenWrap>
              <Shared.Text type="regular" level={1}>
                You will receive
              </Shared.Text>
              <Shared.Text type="bold" level={2} style={{ color: ThemeColor.brand }}>
                + {ToMilions(myStakingContractInfo.unclaimedRewardTokenCount)} {contractInfo.pool.rewardToken.symbol} / per period
              </Shared.Text>
            </Info.FlexBetweenWrap>
            <Shared.Button
              type="detail"
              onClick={() => staking(Number(amount.replace(/,/g, '')))}
              active={contractInfo.pool.isAbleStaking}
            >
              <Shared.Text type="extrabold" level={1}>
                <StakeButtonText>Stake</StakeButtonText>
              </Shared.Text>
            </Shared.Button>
          </BottomBoxInner>
        </Shared.StakingGrayBox>
      )}
    </>
  )
}

export default Section1

const BottomBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 322px;
  button {
    margin-top: 30px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    height: 414px;
    button {
      margin-top: 40px;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    height: 534px;
    button {
      margin-top: 50px;
    }
  }
`

const VerifyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`
const Info = {
  Img: styled.img`
    width: 50px;
    height: 70px;
    object-fit: contain;
    margin: 15px 0;
  `,
  Icon: styled.img`
    width: 70px;
    height: 70px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      width: 100px;
      height: 100px;
    }
  `,
  AmountBox: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    background-color: #2c2c2c;
    border-radius: 5px;
    padding: 0 10px;
    margin-top: 20px;
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      height: 40px;
      margin-top: 40px;
    }
  `,
  AmountInput: styled.input`
    width: 175px;
    height: 80%;
    background-color: #2c2c2c;
    border: none;
    outline: none;
    color: white;
    text-align: right;
    font-size: 14px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      width: 260px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      width: 340px;
    }
  `,
  FlexRightWrap: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      margin-bottom: 40px;
    }
  `,
  FlexBetweenWrap: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `
}

const StakeButtonText = styled.div`
  color: white;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding: 0 80px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 0 35px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: 0 65px;
  }
`
