import React from 'react'
import styled from 'styled-components'

interface EmptyGrayBoxProps {
  children?: React.ReactNode
  style?: React.CSSProperties
}

const EmptyGrayBox = (props: EmptyGrayBoxProps) => {
  const { children, style } = props
  return <StyledEmptyGrayBox style={style}>{children}</StyledEmptyGrayBox>
}

export default EmptyGrayBox

const StyledEmptyGrayBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.ThemeColor.gray};
  border-radius: 20px;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    min-width: 310px;
    padding: 15px;
    text-align: center;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    min-width: 157px;
    padding: 15px;
    text-align: left;
    align-items: flex-start;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    min-width: 279px;
    padding: 20px;
    text-align: left;
    align-items: flex-start;
  }
`
