import React from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'

interface EmptyGrayBoxProps {
  children?: React.ReactNode
  style?: React.CSSProperties
}

const StakingGrayBox = (props: EmptyGrayBoxProps) => {
  const { children, style } = props
  return (
    <StyledEmptyGrayBox style={style}>
      <GrayBoxInnerWrap>{children}</GrayBoxInnerWrap>
    </StyledEmptyGrayBox>
  )
}

export default StakingGrayBox

const StyledEmptyGrayBox = styled.div`
	// min-width: 200px;
	display: flex;
	flex-direction: column;
	background-color: ${(props) => props.theme.ThemeColor.gray};
	border-radius: 20px;
	padding: 13px;
	// TODO: david 체크 필요
	width: 310px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.ThemeColor.gray};
	border-radius: 20px;
	
	margin-bottom: 20px;
	@media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
		width: 451px;
	}
	@media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
		width: 556px;
	}
`;

const GrayBoxInnerWrap = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding: 20px;
`;
