import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import DesktopBackGround from '../assets/image/desktop_bg.png'
import TabletBackGround from '../assets/image/tablet_bg.png'
import MobileBackGround from '../assets/image/mobile_bg.png'
import * as Shared from '../components/Shared'
import { WalletContext } from '../contexts/WalletContext'
import { Wallet } from '../classes'
import { useLocation } from 'react-router-dom'

interface FullLayoutProps {
  children: React.ReactNode
}

interface ContainerProps {
  currentPath: string
}

const FullLayout = (props: FullLayoutProps) => {
  const { children } = props
  const headerRef = useRef<HTMLElement | null>(null)
  const location = useLocation()
  // const [headerHeight, setHeaderHeight] = useState(0)
  const [currentPath, setCurrentPath] = useState<string>(location.pathname)
  const [wallet, setWallet] = useState<Wallet | null>(null)

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  // useEffect(() => {
  //   const marginTop = headerRef.current?.offsetHeight ?? 0
  //   setHeaderHeight(marginTop)
  // }, [headerRef])

  return (
    <Layout.Container id={'full-layout'} currentPath={currentPath}>
      <WalletContext.Provider value={{ wallet, setWallet }}>
        <Layout.Centering>
          <Shared.Header headerRef={headerRef} />
          <Layout.Content style={{ marginTop: `0px` }}>{children}</Layout.Content>
          <Shared.Footer />
        </Layout.Centering>
      </WalletContext.Provider>
    </Layout.Container>
  )
}

const Layout = {
  Container: styled.div<ContainerProps>`
    display: flex;
    justify-content: center;

    background-size: contain;
    background-repeat: no-repeat;
    @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
      ${(props) => {
        if (props.currentPath === '/') {
          return `background-image: url(${MobileBackGround});`
        } else {
          return ``
        }
      }}
    }
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      background-image: url(${TabletBackGround});
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      background-image: url(${DesktopBackGround});
    }
  `,
  Centering: styled.div`
    display: flex;
    width: 100vw;
    max-width: 1366px;
    min-height: 100dvh;

    flex-direction: column;
    position: relative;

    @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
      max-width: ${(props) => `${props.theme.breakpoints.mobile}px`};
    }
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      max-width: ${(props) => `${props.theme.breakpoints.tablet}px`};
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      max-width: ${(props) => `${props.theme.breakpoints.desktop}px`};
    }
  `,
  Content: styled.div<{ style: React.CSSProperties }>`
    margin-top: ${(props) => props.style.marginTop};
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    position: relative;
  `
}

export default FullLayout
