import React from 'react'
import CardItem from './item'
// TODO: Combine the ContractInfo of the classes ContractInfo
import { ContractInfo } from '../../../pages/Staking/controller'

interface StakingCardProps {
  contractInfo: ContractInfo
}

const StakingCard = (props: StakingCardProps) => {
  const { contractInfo } = props

  return <CardItem ContractInfo={contractInfo} />
}

export default StakingCard
