import React, { useEffect, useState } from 'react'
import { ToLocaleWithFixed, ToMilions } from 'src/utils/number'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import { ContractInfo, MyStakingContractInfo } from '../Staking/controller'

interface Section2Props {
  contractInfo: ContractInfo
  myStakingContractInfo: MyStakingContractInfo
  unstaking: () => Promise<void>
}

const Section2 = (props: Section2Props) => {
  const { contractInfo, myStakingContractInfo, unstaking } = props

  return (
    <>
      <SubTitleWrap>
        <Shared.Text type="bold" level={3} style={{ marginRight: 'auto' }}>
          My Staked
        </Shared.Text>
      </SubTitleWrap>
      <Shared.StakingGrayBox>
        <BoxInner>
          <HalfWrap>
            <TextContainer>
              <Shared.Text type="regular" level={1}>
                MY Staked
              </Shared.Text>
            </TextContainer>
          </HalfWrap>
          <div>
            <TextContainer>
              <Shared.Text type="bold" level={1}>
                {ToMilions(myStakingContractInfo.stakedTokenCount)} {contractInfo.pool.stakingToken.symbol}
              </Shared.Text>
              <Shared.Text type="regular" level={1}>
                $ {ToLocaleWithFixed(myStakingContractInfo.stakedTokenPrice)}
              </Shared.Text>
            </TextContainer>
          </div>
        </BoxInner>
        <BoxInner>
          <HalfWrap>
            <TextContainer>
              <Shared.Text type="regular" level={1}>
                My Rewards
              </Shared.Text>
            </TextContainer>
          </HalfWrap>
          <div>
            <TextContainer>
              <Shared.Text type="bold" level={1}>
                + {ToMilions(myStakingContractInfo.unclaimedRewardTokenCount)} {contractInfo.pool.stakingToken.symbol}
              </Shared.Text>
              <Shared.Text type="regular" level={1}>
                $ {ToLocaleWithFixed(myStakingContractInfo.unclaimedRewardTokenPrice)}
              </Shared.Text>
            </TextContainer>
          </div>
        </BoxInner>
        <BoxInner>
          <HalfWrap>
            <TextContainer>
              <Shared.Text type="regular" level={1}>
                MY Share
              </Shared.Text>
            </TextContainer>
          </HalfWrap>
          <div>
            <TextContainer>
              <Shared.Text type="bold" level={1}>
                {ToLocaleWithFixed(myStakingContractInfo.share)} %
              </Shared.Text>
            </TextContainer>
          </div>
        </BoxInner>
        <Shared.Button
          type="detail"
          onClick={() => unstaking()}
          active={contractInfo.pool.isAbleUnstaking}
          style={{ marginTop: '40px' }}
        >
          <Shared.Text type="extrabold" level={1}>
            <StakeButtonText>Unstake</StakeButtonText>
          </Shared.Text>
        </Shared.Button>
      </Shared.StakingGrayBox>
    </>
  )
}

export default Section2

const BoxInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: right;
  width: 100%;
`

const SubTitleWrap = styled.div`
  width: 310px;
  text-align: left;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 451px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 556px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const HalfWrap = styled.div`
  display: flex;
  width: 40%;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 60%;
  }
`

const StakeButtonText = styled.div`
  color: white;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding: 0 80px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 0 35px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: 0 65px;
  }
`
