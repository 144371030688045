import Web3 from 'web3'

export const ToLocaleWithFixed = (value: number) => {
  return Number(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const ToMilions = (value: number) => {
  const million = (10 ** 6)
  const millionFormat = (value / million).toLocaleString(undefined, {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3
  })
  return `${millionFormat}M`
}
