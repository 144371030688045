import Web3 from 'web3'
import { isAfter } from 'date-fns'
import { isMobile } from 'react-device-detect'
import { Contract, Wallet } from '../../classes'
import { ContractInfo, MyStakingContractInfo } from '../../pages/Staking/controller'

const ERC20_TOKEN_ABI = require('./erc20.abi.json')
const ERC721_TOKEN_ABI = require('./erc721.abi.json')

export type ConnectWalletReturnType = [Web3, string] | [null, null]

export const checkNetwork = async (chainId: number): Promise<void> => {
  const currentId: string = await window.ethereum.request({ method: 'net_version' })
  const currentNetworkId = parseInt(currentId)
  const currentNetworkIdHex = '0x' + currentNetworkId.toString(16)

  const chainIdHex = chainId ? '0x' + chainId?.toString(16) : ''

  if (chainIdHex && chainIdHex !== currentNetworkIdHex) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdHex }]
      })
    } catch (e: any) {
      if (e.code) {
        throw e
      }
      throw new Error('Please switch to the network')
    }
  }
}

export const connectWallet = async (chainId?: number): Promise<ConnectWalletReturnType> => {
  if (!window.ethereum) {
    if (isMobile) {
      window.open(`https://metamask.app.link/dapp/${window.location.href}`, '_blank')
      return [null, null]
    } else {
      throw new Error('Please install MetaMask!')
    }
  }

  if (chainId) {
    await checkNetwork(chainId)
  }

  try {
    const web3Instance = new Web3(window.ethereum)
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    return [web3Instance, accounts[0]]
  } catch (e: any) {
    if (e.code) {
      throw e
    }
    throw new Error('The network is unstable. Please try again later.')
  }
}

export const getContract = (web3: Web3, abi: any, contractAddress: string) => {
  try {
    const contract = new web3.eth.Contract(abi, contractAddress)
    return contract
  } catch (e: any) {
    if (e.code) {
      throw e
    }
    throw new Error('The network is unstable. Please try again later.')
  }
}

export const getContractInfo = async ({ contract, pool, web3 }: Contract): Promise<ContractInfo> => {
  try {
    const stakingToken = pool.stakingToken.tokenPrices.find((tokenPrice) => tokenPrice.currency === 'usd')
    const stakingTokenPrice = stakingToken ? stakingToken.price : 0
    // const nftlist = pool.nftlist

    const name = await contract.methods.name().call()
    const isLocked = await contract.methods.isLocked().call()
    const limitWei = await contract.methods.limit().call()
    const limit = parseFloat(web3.utils.fromWei(limitWei, 'ether'))
    const durationWei = await contract.methods.DURATION().call() // wei?
    const duration = Number(durationWei)
    const year = 365 * 24 * 60 * 60

    const totalSupplyWei = await contract.methods.totalSupply().call() // TVL (Total Value Locked)
    const totalSupply = parseFloat(Web3.utils.fromWei(totalSupplyWei, 'ether'))
    const totalSupplyPrice = totalSupply * stakingTokenPrice

    const tokenContract = getContract(web3, ERC20_TOKEN_ABI, pool.rewardToken.address) as Contract['contract']
    const totalRewardWei = (await tokenContract.methods.balanceOf(pool.address).call()) as string // Reward Token Balance
    const totalReward = parseFloat(Web3.utils.fromWei(totalRewardWei, 'ether'))
    const totalRewardPrice = totalReward * stakingTokenPrice

    const annualCycles = year / duration
    const rewardToStakeRatio = totalSupplyPrice ? totalRewardPrice / totalSupplyPrice : 0
    const apy = annualCycles * rewardToStakeRatio * 100

    return {
      name,
      limit,
      address: contract._address,
      totalSupply,
      totalSupplyPrice,
      totalReward,
      totalRewardPrice,
      isLocked,
      apy,
      pool
    }
  } catch (e: any) {
    if (e.code) {
      throw e
    }
    throw new Error('The network is unstable. Please try again later.')
  }
}

export const getMyStakingInfo = async (
  account: string,
  { contract, pool, web3 }: Contract
): Promise<MyStakingContractInfo> => {
  try {
    const rewardToken = pool.rewardToken.tokenPrices.find((tokenPrice) => tokenPrice.currency === 'usd')
    const rewardTokenPrice = rewardToken ? rewardToken.price : 0

    const stakedAmountWei = await contract.methods.balanceOf(account).call()
    const stakedAmount = parseFloat(web3.utils.fromWei(stakedAmountWei, 'ether'))
    const stakedTokenPrice = stakedAmount * rewardTokenPrice

    const unclaimedRewardAmountWei = await contract.methods.earned(account).call()
    const unclaimedRewardAmount = parseFloat(web3.utils.fromWei(unclaimedRewardAmountWei, 'ether'))
    const unclaimedRewardTokenPrice = unclaimedRewardAmount * rewardTokenPrice

    const tokenContract = getContract(web3, ERC20_TOKEN_ABI, pool.stakingToken.address) as Contract['contract']
    const balanceWei = await tokenContract.methods.balanceOf(account).call()
    const balance = parseFloat(Web3.utils.fromWei(balanceWei, 'ether'))

    const share = stakedTokenPrice ? (unclaimedRewardTokenPrice / stakedTokenPrice) * 100 : 0

    return {
      stakedTokenCount: stakedAmount,
      stakedTokenPrice,
      unclaimedRewardTokenCount: unclaimedRewardAmount,
      unclaimedRewardTokenPrice,
      share,
      balance
    }
  } catch (e: any) {
    if (e.code) {
      throw e
    }
    throw new Error('The network is unstable. Please try again later.')
  }
}

export const stake = async (
  amount: number,
  selectedContract: Contract,
  selectedContractInfo: ContractInfo,
  myStakingContractInfo: MyStakingContractInfo,
  wallet: Wallet
) => {
  if (amount === 0) throw new Error('There is no quantity.')
  if (amount < selectedContractInfo.limit) throw new Error('The minimum amount is ' + selectedContractInfo.limit + '.')
  if (!isAfter(new Date(), new Date(selectedContractInfo.pool.startedAt)))
    throw new Error('This is not a staking period. Please check the period.')
  if (isAfter(new Date(), new Date(selectedContractInfo.pool.finishedAt))) throw new Error('This pool is finished.')

  const { contract, web3, pool } = selectedContract
  const { network, stakingToken } = pool
  const { chainId } = network
  const { address: stakingTokenAddress } = stakingToken

  await checkNetwork(chainId)

  // check token balance
  if (myStakingContractInfo.balance < amount) {
    throw new Error('Insufficient balance.')
  }

  if (pool.nftlist.length > 0) {
    let passed = false
    for (const nftAddress of pool.nftlist) {
      const nftContract = getContract(web3, ERC721_TOKEN_ABI, nftAddress) as Contract['contract']
      const nftCount = await nftContract.methods.balanceOf(wallet.account).call()
      if (nftCount != 0) {
        passed = true
        break
      }
    }
    if (!passed) {
      throw new Error(`Please ensure you have the required NFT (address: ${pool.nftlist.join(',')}) in your wallet.`)
    }
  }

  try {
    // approve to contract
    const tokenContract = getContract(web3, ERC20_TOKEN_ABI, stakingTokenAddress) as Contract['contract']
    const amountWei = Web3.utils.toWei(amount, 'ether')
    const approveTx = await tokenContract.methods.approve(contract._address, amountWei).encodeABI()
    const approveHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: wallet.account,
          to: stakingTokenAddress,
          data: approveTx
        }
      ]
    })

    // stake to contract
    const stakeTx = await contract.methods.stake(amountWei).encodeABI()
    const stakeHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: wallet.account,
          to: contract._address,
          data: stakeTx
        }
      ]
    })
  } catch (e: any) {
    if (e.code) {
      throw e
    }
    throw new Error('The network is unstable. Please try again later.')
  }
}

export const unstake = async (
  selectedContract: Contract,
  selectedContractInfo: ContractInfo,
  myStakingContractInfo: MyStakingContractInfo,
  wallet: Wallet
) => {
  if (!isAfter(new Date(), new Date(selectedContractInfo.pool.startedAt)))
    throw new Error('This is not a staking period. Please check the period.')
  if (!isAfter(new Date(), new Date(selectedContract.pool.finishedAt)) && selectedContractInfo.isLocked)
    throw new Error('Please wait until the staking period is over.')

  const { contract, pool } = selectedContract
  const { network } = pool
  const { chainId } = network

  await checkNetwork(chainId)

  // check token balance
  if (
    selectedContractInfo.totalReward <
    myStakingContractInfo.stakedTokenCount + myStakingContractInfo.unclaimedRewardTokenCount
  )
    throw new Error('Insufficient balance.')

  try {
    const exitTx = await contract.methods.exit().encodeABI()
    const exitHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        {
          from: wallet.account,
          to: contract._address,
          data: exitTx
        }
      ]
    })
  } catch (e: any) {
    if (e.code) {
      throw e
    }
    throw new Error('The network is unstable. Please try again later.')
  }
}
