import React from 'react';

interface DashboardViewProps {
	title: string;
}

const OverviewView = (props: DashboardViewProps) => {
	const { title } = props;
	return (
		<div>
			<h1>{title}</h1>
		</div>
	);
};

export default OverviewView;
