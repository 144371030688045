import React from 'react'
import styled from 'styled-components'
import BigLogoImg from '../../assets/image/biglogo.png'
import TickerImg from '../../assets/image/ticker.png'
import NFTCardImg from '../../assets/image/nfc_card.png'
import ShareImg from '../../assets/image/share.svg'
import NFTCardImg2 from '../../assets/image/nfc_card2.png'
import SandboxLogo from '../../assets/image/sandbox.svg'
import OpenseaLogo from '../../assets/image/opensea.svg'
import VerifiedImg from '../../assets/image/verified.svg'
import Line from '../../assets/image/line_amount.svg'
import Hambuger from '../../assets/image/hambuger.svg'
import Close from '../../assets/image/close.svg'
import Github from '../../assets/image/github.svg'
import X from '../../assets/image/x.svg'
import Info from '../../assets/image/info.svg'
import Medium from '../../assets/image/medium.svg'
import Telegram from '../../assets/image/img_logo_telegram.svg'

interface MMVGIconProps {
  style?: React.CSSProperties
  onClick?: () => void
}

interface ButtonProps {
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onTouchStart?: () => void
  onTouchEnd?: () => void
}

interface ImageProps {
  src: string
  alt: string
}

export const BigLogo = (props: ButtonProps) => {
  const { onClick } = props
  return <StyledBigLogo src={BigLogoImg} alt="Logo" onClick={onClick} />
}

export const Ticker = (props: { style: React.CSSProperties }) => {
  return <StyeldTicker src={TickerImg} alt="Ticker" style={props.style} />
}
export const NFTCard = () => {
  return <StyledNFTCard src={NFTCardImg} alt="NFTCard" />
}

export const ShareBtn = (props: MMVGIconProps) => {
  const { style, onClick } = props
  return <StyledShareImg src={ShareImg} style={style} alt="ShareImg" onClick={onClick} />
}

export const SmallNFTCard = () => {
  return <StyledSmallNFTCard src={NFTCardImg2} alt="NFTCard" />
}

export const SandboxLogoImg = () => {
  return <StyledSandboxLogo src={SandboxLogo} alt="SandboxLogo" />
}

export const OpenseaLogoImg = () => {
  return <StyledOpenseaLogo src={OpenseaLogo} alt="OpenseaLogo" />
}

export const Verified = (props: MMVGIconProps) => {
  return <StyledVerifiedImg src={VerifiedImg} alt={'Verified'} {...props} />
}

export const LineAmount = () => {
  return <StyledLineAmount src={Line} alt="Line" />
}

export const HambugerImg = (props: ButtonProps) => {
  const { onClick } = props
  return <StyledHambuger src={Hambuger} alt="Hambuger" onClick={onClick} />
}

export const CloseImg = (props: ButtonProps) => {
  const { onClick } = props
  return <StyledClose src={Close} alt="Close" onClick={onClick} />
}

export const GithubImg = (props: ButtonProps) => {
  const { onClick } = props
  return <StyledGithub src={Github} alt="Github" onClick={onClick} />
}

export const XImg = (props: ButtonProps) => {
  const { onClick } = props
  return <StyledX src={X} alt="X" onClick={onClick} />
}

export const TelegramImg = (props: ButtonProps) => {
  const { onClick } = props
  return <StyledTelegram src={Telegram} alt="Discord" onClick={onClick} />
}

export const MediumImg = (props: ButtonProps) => {
  const { onClick } = props
  return <StyeldMedium src={Medium} alt="Medium" onClick={onClick} />
}

export const InfoImg = (props: ButtonProps) => {
  const { onClick } = props
  return <StyledInfo src={Info} alt="Info" onClick={onClick} />
}

const BaseImg = styled.img<ButtonProps>``

const StyledBigLogo = styled(BaseImg)`
  width: 220px;
  height: 66.9px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 260px;
    height: 82px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 566px;
    height: 178px;
  }
`

const StyeldTicker = styled(BaseImg)<{ style: React.CSSProperties }>`
  width: 65px;
  height: 68px;
  object-fit: contain;
  padding: ${(props) => props.style.padding};
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 60px;
    height: 63px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 125px;
    height: 130px;
  }
`

const StyledNFTCard = styled(BaseImg)`
  width: 91px;
  height: 91px;

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 90px;
    height: 90px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 211px;
    height: 211px;
  }
`

const StyledShareImg = styled(BaseImg)`
  width: 30px;
  height: 30px;
  object-fit: contain;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 44px;
    height: 44px;
  }
`

const StyledSmallNFTCard = styled(BaseImg)`
  width: 50px;
  height: 70px;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 72px;
    height: 100px;
  }
`

const StyledSandboxLogo = styled(BaseImg)`
  width: 66px;
  height: 20px;
  object-fit: contain;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 122px;
    height: 36px;
  }
`

const StyledOpenseaLogo = styled(BaseImg)`
  width: 87px;
  height: 20px;
  object-fit: contain;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 141px;
    height: 33px;
  }
`

const StyledVerifiedImg = styled(BaseImg)`
  width: 15px;
  height: 15px;
  object-fit: contain;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 24px;
    height: 24px;
  }
`

const StyledLineAmount = styled(BaseImg)`
  height: 14px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    height: 20px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    height: 28px;
  }
`

const StyledHambuger = styled(BaseImg)`
  width: 30px;
  height: 30px;
  object-fit: contain;
  filter: invert(1);
`

const StyledClose = styled(BaseImg)`
  width: 15px;
  height: 15px;
  object-fit: contain;
`

const StyledGithub = styled(BaseImg)`
  width: 30px;
  height: 30px;
  object-fit: contain;
`

const StyledX = styled(BaseImg)`
  width: 30px;
  height: 30px;
  object-fit: contain;
`

const StyledTelegram = styled(BaseImg)`
  width: 30px;
  height: 30px;
  object-fit: contain;
`

const StyledInfo = styled(BaseImg)`
  margin: 2.5px;
`

const StyeldMedium = styled(BaseImg)`
  width: 30px;
  height: 30px;
  object-fit: contain;
`
