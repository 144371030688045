import React from 'react'
import styled from 'styled-components'
import * as Shared from '../Shared'

enum StakingType {
  Hybrid = 'hybrid',
  Locked = 'locked',
  Flexible = 'flexible'
}
interface StakingTypeProps {
  type: 'hybrid' | 'locked' | 'flexible'
}

const StakinType = (props: StakingTypeProps) => {
  const { type } = props
  return (
    <StyledStakingType type={type}>
      <Shared.Text type="regular" level={1}>
        {type}
      </Shared.Text>
    </StyledStakingType>
  )
}

export default StakinType

const StyledStakingType = styled.div<StakingTypeProps>`
  height: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  padding: 2px 20px;
  ${(props) => {
    switch (props.type) {
      case StakingType.Hybrid:
        return `
        background-color: #214681;
        color: #00d8ff;`
      case StakingType.Locked:
        return `
        background-color: #482b2b;
        color: #f4007a;`
      case StakingType.Flexible:
        return `
        background-color: #2c483a;
        color: #38f400;`
      default:
        return `background-color: ${props.theme.ThemeColor.gray};`
    }
  }}
`
