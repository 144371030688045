import React from 'react'
import * as Shared from '../../components/Shared'
import MMVG from '../index.json'
import Section1 from './section1'
import Section2 from './section2'
import Section3 from './section3'

interface DashboardViewProps {
  title: string
  totalValueCount: number
  totalSentRewardPrice: number
  totalSentReward: number
  totalValuePrice: number
}

const MainView = (props: DashboardViewProps) => {
  const { totalValueCount, totalSentRewardPrice, totalSentReward, totalValuePrice } = props
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ marginBottom: '20px' }}>
          <Shared.MMVGIcon.BigLogo />
        </div>
        <div style={{ marginBottom: '10px' }}>
          <Shared.Text type="semibold" level={1} style={{ textAlign: 'center' }}>
            {MMVG.home.desc.top}
          </Shared.Text>
        </div>
        <div>
          <Shared.Text type="semibold" level={2} style={{ textAlign: 'center' }}>
            {MMVG.home.desc.bottom}
          </Shared.Text>
        </div>
      </div>
      <Section1
        totalValueCount={totalValueCount}
        totalSentRewardPrice={totalSentRewardPrice}
        totalSentReward={totalSentReward}
        totalValuePrice={totalValuePrice}
      />
      <Section2 />
      <Section3 />
    </>
  )
}

export default MainView
