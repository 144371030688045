import React, { useContext, useEffect, useState } from 'react'
import DetailView from '../'
import { Contract, Pool, Wallet } from '../../../classes'
import Web3 from 'web3'
import { useNavigate, useParams } from 'react-router-dom'
import { connectWallet, getContract, getContractInfo, getMyStakingInfo, stake, unstake } from '../../../service/web3'
import { ContractInfo, MyStakingContractInfo } from '../../Staking/controller'
import { GetPoolData } from '../../../service/pool'
import { WalletContext } from '../../../contexts/WalletContext'

const POOL_V3_ABI = require('../../../service/web3/poolv3.abi.json')

const DetailController = () => {
  const params = useParams()
  const selectedId = params.id
  const [selectedContract, setSelectedContract] = useState<Contract | undefined>(undefined)
  const [selectedContractInfo, setSelectedContractInfo] = useState<ContractInfo | undefined>(undefined)
  const [myStakingContractInfo, setMyStakingContractInfo] = useState<MyStakingContractInfo | undefined>(undefined)
  const { wallet, setWallet } = useContext(WalletContext)
  const navigate = useNavigate()

  useEffect(() => {
    const exec = async () => {
      try {
        const [web3, account] = await connectWallet()
        if (web3 == null || account == null) {
          return navigate('/')
        }
        setWallet(new Wallet({ web3, account }))
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message)
        } else {
          alert('Please try again after connecting your wallet.')
        }
        navigate('/')
      }
    }

    exec()
  }, [])

  const onClickStake = async (
    amount: number,
    contract: Contract,
    selectedContractInfo: ContractInfo,
    myStakingContractInfo: MyStakingContractInfo,
    wallet: Wallet
  ) => {
    try {
      await stake(amount, contract, selectedContractInfo, myStakingContractInfo, wallet)
      alert('Stake success.')
      navigate('/staking')
    } catch (e: unknown) {
      if (e instanceof Error) {
        alert(e.message)
      } else {
        alert('Please try again.')
      }
    }
  }

  const onClickUnstake = async (
    contract: Contract,
    selectedContractInfo: ContractInfo,
    myStakingContractInfo: MyStakingContractInfo,
    wallet: Wallet
  ) => {
    try {
      await unstake(contract, selectedContractInfo, myStakingContractInfo, wallet)
      alert('Unstake success.')
      navigate('/staking')
    } catch (e: unknown) {
      if (e instanceof Error) {
        alert(e.message)
      } else {
        alert('Please try again.')
      }
    }
  }

  const init = async () => {
    if (wallet == null) return
    try {
      const pools = await GetPoolData()
      // TODO: 타입정의
      if (pools.length > 0) {
        const resultPool: Pool[] = pools.map((pool: any) => {
          return new Pool(pool)
        })

        const resultContract = await Promise.all(
          resultPool.map(async (pool) => {
            const { address, network } = pool
            const web3 = new Web3(network.rpc)
            return new Contract({
              contract: getContract(web3, POOL_V3_ABI, address),
              web3,
              pool
            })
          })
        )
        const selectedContract = resultContract.find((contract) => contract.pool.id.toString() === selectedId)
        if (!selectedContract) return alert('Invalid access.')
        setSelectedContract(selectedContract)

        const selectedContractInfo = await getContractInfo(selectedContract)
        setSelectedContractInfo(selectedContractInfo)
        const myStakingContractInfo = await getMyStakingInfo(wallet.account, selectedContract)
        setMyStakingContractInfo(myStakingContractInfo)
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        return alert(e.message)
      } else {
        return alert('Please try again.')
      }
    }
  }

  useEffect(() => {
    const timerId = window.setInterval(init, 1000 * 10 * 10)
    init()

    return () => {
      window.clearInterval(timerId)
    }
  }, [wallet])

  return selectedContract && selectedContractInfo && myStakingContractInfo && wallet ? (
    <DetailView
      contractInfo={selectedContractInfo}
      myStakingContractInfo={myStakingContractInfo}
      stake={(amount: number) =>
        onClickStake(amount, selectedContract, selectedContractInfo, myStakingContractInfo, wallet)
      }
      unstake={() => onClickUnstake(selectedContract, selectedContractInfo, myStakingContractInfo, wallet)}
    />
  ) : (
    <></>
  )
}

export default DetailController
