import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Shared from '../'

// TODO: Combine the ContractInfo of the classes ContractInfo
import { ContractInfo } from '../../../pages/Staking/controller'
import MMVG from '../../../pages/index.json'
import { ThemeColor } from '../../../theme/color'
import { useNavigate } from 'react-router-dom'
import NetworkLogoSvg from '../../../assets/image/img_ticker_eth.svg'
import { handleShareClick } from '../../../utils/shareURL'
import { ToLocaleWithFixed, ToMilions } from 'src/utils/number'

interface StakingCardProps {
  ContractInfo: ContractInfo
}

const CardItem = (props: StakingCardProps) => {
  const { ContractInfo } = props
  const { name } = ContractInfo
  const { id, stakingToken, period, startedAt, finishedAt } = ContractInfo.pool
  const { symbol } = stakingToken
  const navigate = useNavigate()

  const [isMobile, setIsMobile] = useState(false)

  const handleMoveToDetail = (id: number | string) => {
    navigate(`/staking/${id}`)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <MobileCard.Container>
      <MobileCard.ContentsWrap isMobile={isMobile}>
        <Shared.MMVGIcon.ShareBtn
          style={{ position: 'absolute', right: '0', cursor: 'pointer' }}
          onClick={() => handleShareClick(id)}
        />

        {isMobile && (
          <MobileCard.ImageWrap isMobile={isMobile}>
            <StakingTokenLogo src={stakingToken.iconUrl} alt="Logo" onClick={() => handleMoveToDetail(id)} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <NetworkLogo src={NetworkLogoSvg} alt={'ERC-20'} />
                <Shared.Text type="regular" level={2}>
                  ERC-20
                </Shared.Text>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', flexWrap: 'wrap' }}
                onClick={() => handleMoveToDetail(id)}
              >
                <StakingName>
                  <Shared.Text type="bold" level={3}>
                    {name}
                  </Shared.Text>
                </StakingName>
                <Shared.StakinType type={ContractInfo.isLocked ? 'locked' : 'flexible'} />
              </div>
            </div>
          </MobileCard.ImageWrap>
        )}

        {isMobile && (
          <MobileCard.DescWrap isMobile={isMobile}>
            <Shared.Text type="regular" level={1}>
              {period}
            </Shared.Text>
            <Shared.Graph startedAt={startedAt} finishedAt={finishedAt} />
          </MobileCard.DescWrap>
        )}

        {!isMobile && (
          <MobileCard.ImageWrap isMobile={isMobile}>
            <StakingTokenLogo src={stakingToken.iconUrl} alt="Logo" onClick={() => handleMoveToDetail(id)} />
          </MobileCard.ImageWrap>
        )}

        {!isMobile && (
          <MobileCard.DescWrap isMobile={isMobile}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <NetworkLogo src={NetworkLogoSvg} alt={'ERC-20'} />
              <Shared.Text type="regular" level={2}>
                ERC-20
              </Shared.Text>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', flexWrap: 'wrap' }}
              onClick={() => handleMoveToDetail(id)}
            >
              <StakingName>
                <Shared.Text type="bold" level={3}>
                  {name}
                </Shared.Text>
              </StakingName>
              <Shared.StakinType type={ContractInfo.isLocked ? 'locked' : 'flexible'} />
            </div>
            <Shared.Text type="regular" level={1}>
              {period}
            </Shared.Text>
            <Shared.Graph startedAt={startedAt} finishedAt={finishedAt} />
          </MobileCard.DescWrap>
        )}

        <MobileCard.EarnWrap>
          <Shared.Text type="regular" level={1} style={{ color: ThemeColor.inactive }}>
            APY
          </Shared.Text>
          <Shared.Text type="bold" level={2}>
            {ToLocaleWithFixed(ContractInfo.apy)}%
          </Shared.Text>
          <Shared.Text type="regular" level={1} style={{ color: ThemeColor.inactive }}>
            Total Staked
          </Shared.Text>
          <Shared.Text type="bold" level={2}>
            {ToMilions(ContractInfo.totalSupply)} {symbol}
          </Shared.Text>
          <Shared.Text type="light" level={1}>
            $ {ToLocaleWithFixed(ContractInfo.totalSupplyPrice)}
          </Shared.Text>
        </MobileCard.EarnWrap>

        <MobileCard.ButtonWrap>
          <Shared.Button type="navsmall" onClick={() => handleMoveToDetail(id)} active={true}>
            <Shared.Text type="extrabold" level={1}>
              <StakeButtonText>Stake</StakeButtonText>
            </Shared.Text>
          </Shared.Button>
        </MobileCard.ButtonWrap>
      </MobileCard.ContentsWrap>
    </MobileCard.Container>
  )
}

const NetworkLogo = styled.img`
  width: 20px;
  height: 20px;
  padding-right: 10px;
`

const StakeButtonText = styled.div`
  color: white;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    padding: 0 80px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    padding: 0 35px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: 0 65px;
  }
`

const MobileCard = {
  Container: styled.div`
    display: flex;
    background-color: ${(props) => props.theme.ThemeColor.gray};
    border-radius: 20px;

    @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
      padding: 25px;
      margin: 0 0 20px 0;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      padding: 25px;
      margin: 0 10px 20px 10px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      padding: 25px;
      margin: 0 120px 20px 120px;
    }
  `,
  ContentsWrap: styled.div<{ isMobile: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
  `,
  ImageWrap: styled.div<{ isMobile: boolean }>`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')};
    justify-content: ${(props) => (props.isMobile ? 'left' : 'center')};
    margin-top: ${(props) => (props.isMobile ? '10px' : '0')};
    align-items: center;
  `,
  DescWrap: styled.div<{ isMobile: boolean }>`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${(props) => (props.isMobile ? '20px' : '0')};
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      width: 230px;
      overflow: hidden;
      margin-right: 30px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      width: 350px;
      overflow: hidden;
    }
  `,
  EarnWrap: styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  ButtonWrap: styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  `
}

const StakingTokenLogo = styled.img`
  cursor: pointer;
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    width: 84px;
    height: 84px;
    padding: 10px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    width: 84px;
    height: 84px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 140px;
    height: 140px;
  }
`

const StakingName = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-right: 10px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin-right: 5px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-right: 15px;
  }
`

export default CardItem
