import React, { useContext, useEffect, useMemo, useState } from 'react'
import StakingView from '..'
import { GetPoolData } from '../../../service/pool'
import { Contract, Pool, Wallet } from '../../../classes'
import Web3 from 'web3'
import { connectWallet, getContract, getContractInfo, getMyStakingInfo } from 'src/service/web3'
import { WalletContext } from '../../../contexts/WalletContext'
import { useNavigate } from 'react-router-dom'

const POOL_V3_ABI = require('../../../service/web3/poolv3.abi.json')

// TODO: Combine the ContractInfo of the classes ContractInfo
export class ContractInfo {
  name!: string
  address!: string
  totalSupply!: number
  totalSupplyPrice!: number
  totalReward!: number
  totalRewardPrice!: number
  isLocked!: boolean
  apy: number
  pool: Pool
  limit: number

  constructor(props: {
    name: string
    address: string
    totalSupply: number
    totalSupplyPrice: number
    totalReward: number
    totalRewardPrice: number
    isLocked: boolean
    apy: number
    pool: Pool
    limit: number
  }) {
    this.name = props.name
    this.address = props.address
    this.totalSupply = props.totalSupply
    this.totalSupplyPrice = props.totalSupplyPrice
    this.totalReward = props.totalReward
    this.totalRewardPrice = props.totalRewardPrice
    this.isLocked = props.isLocked
    this.apy = props.apy
    this.pool = props.pool
    this.limit = props.limit
  }
}
export interface ContractSummary {
  totalSupplyCount: number
  totalSupplyPrice: number
  totalRewardAmountCount: number
  totalRewardAmountPrice: number
  totalSupplyCountByLocked: number
  totalSupplyPriceByLocked: number
}
export class MyStakingContractInfo {
  stakedTokenCount: number
  stakedTokenPrice: number
  unclaimedRewardTokenCount: number
  unclaimedRewardTokenPrice: number
  balance: number
  share: number

  constructor(props: {
    stakedTokenCount: number
    stakedTokenPrice: number
    unclaimedRewardTokenCount: number
    unclaimedRewardTokenPrice: number
    balance: number
    share: number
  }) {
    this.stakedTokenCount = props.stakedTokenCount
    this.stakedTokenPrice = props.stakedTokenPrice
    this.unclaimedRewardTokenCount = props.unclaimedRewardTokenCount
    this.unclaimedRewardTokenPrice = props.unclaimedRewardTokenPrice
    this.balance = props.balance
    this.share = props.share
  }
}
export interface MyStakingContractSummary {
  totalStakedTokenCount: number
  totalStakedTokenPrice: number
  totalUnclaimedRewardTokenCount: number
  totalUnclaimedRewardTokenPrice: number
}

const StakingController = () => {
  const [contractInfos, setContractInfos] = useState<ContractInfo[]>([])
  const [myStakingContractInfos, setMyStakingContractInfos] = useState<MyStakingContractInfo[]>([])
  const { wallet, setWallet } = useContext(WalletContext)
  const navigate = useNavigate()

  useEffect(() => {
    const exec = async () => {
      try {
        const [web3, account] = await connectWallet()
        if (web3 == null || account == null) {
          return navigate('/')
        }
        setWallet(new Wallet({ web3, account }))
      } catch (error: unknown) {
        if (error instanceof Error) {
          alert(error.message)
        } else {
          alert('Please try again after connecting your wallet.')
        }
        navigate('/')
      }
    }

    exec()
  }, [])

  const contractSummary = useMemo<ContractSummary>(() => {
    const totalSupplyCount =
      contractInfos?.reduce((acc: number, cur) => {
        return acc + cur.totalSupply
      }, 0) ?? 0
    const totalSupplyPrice =
      contractInfos?.reduce((acc: number, cur) => {
        return acc + cur.totalSupplyPrice
      }, 0) ?? 0
    const totalRewardAmountCount =
      contractInfos?.reduce((acc: number, cur) => {
        return acc + cur.totalReward
      }, 0) ?? 0
    const totalRewardAmountPrice =
      contractInfos?.reduce((acc: number, cur) => {
        return acc + cur.totalRewardPrice
      }, 0) ?? 0
    const totalSupplyCountByLocked =
      contractInfos?.reduce((acc: number, cur) => {
        if (!cur.isLocked) return acc
        return acc + cur.totalSupply
      }, 0) ?? 0
    const totalSupplyPriceByLocked =
      contractInfos?.reduce((acc: number, cur) => {
        if (!cur.isLocked) return acc
        return acc + cur.totalSupplyPrice
      }, 0) ?? 0
    return {
      totalSupplyCount,
      totalSupplyPrice,
      totalRewardAmountCount,
      totalRewardAmountPrice,
      totalSupplyCountByLocked,
      totalSupplyPriceByLocked
    }
  }, [contractInfos])

  const MyContractSummary = useMemo<MyStakingContractSummary>(() => {
    const totalStakedTokenCount =
      myStakingContractInfos?.reduce((acc: number, cur) => {
        return acc + cur.stakedTokenCount
      }, 0) ?? 0
    const totalStakedTokenPrice =
      myStakingContractInfos?.reduce((acc: number, cur) => {
        return acc + cur.stakedTokenPrice
      }, 0) ?? 0
    const totalUnclaimedRewardTokenCount = myStakingContractInfos?.reduce((acc: number, cur) => {
      return acc + cur.unclaimedRewardTokenCount
    }, 0)
    const totalUnclaimedRewardTokenPrice = myStakingContractInfos?.reduce((acc: number, cur) => {
      return acc + cur.unclaimedRewardTokenPrice
    }, 0)

    return {
      totalStakedTokenCount,
      totalStakedTokenPrice,
      totalUnclaimedRewardTokenCount,
      totalUnclaimedRewardTokenPrice
    }
  }, [myStakingContractInfos])

  const init = async () => {
    if (wallet == null) return

    try {
      const pools = await GetPoolData()
      // TODO: 타입정의
      if (pools.length > 0) {
        const resultPool: Pool[] = pools.map((pool: any) => {
          return new Pool(pool)
        })

        const resultContract = await Promise.all(
          resultPool.map(async (pool) => {
            const { address, network } = pool
            const web3 = new Web3(network.rpc)
            return new Contract({
              contract: getContract(web3, POOL_V3_ABI, address),
              web3,
              pool
            })
          })
        )

        const resultContractInfo = await Promise.all(
          resultContract.map(async (contract) => {
            return await getContractInfo(contract)
          })
        )
        setContractInfos(resultContractInfo)

        const resultMyStakingInfo = await Promise.all(
          resultContract.map(async (contract) => {
            return await getMyStakingInfo(wallet!.account, contract)
          })
        )
        setMyStakingContractInfos(resultMyStakingInfo)
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        return alert(e.message)
      }
    }
  }

  useEffect(() => {
    const timerId = window.setInterval(init, 1000 * 10 * 10)
    init()

    return () => {
      window.clearInterval(timerId)
    }
  }, [wallet])

  return (
    <StakingView
      contractInfos={contractInfos}
      contractSummary={contractSummary}
      myStakingContractSummary={MyContractSummary}
    />
  )
}

export default StakingController
