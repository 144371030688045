import React from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import MMVG from '../index.json'
import { useNavigate } from 'react-router-dom'

const Section2 = () => {
  const navigate = useNavigate()
  const BoxArr = [
    {
      title: 'Level 1',
      desc: MMVG.home.level1
    },
    {
      title: 'Level 2',
      desc: MMVG.home.level2
    },
    {
      title: 'Level 3',
      desc: MMVG.home.level3
    }
  ]
  const hadleClickStaking = () => {
    navigate('/staking')
  }
  return (
    <SectionWrap>
      <BoxWrap>
        <Shared.Button style={{ minWidth: '350px' }} type="big" active={true} onClick={hadleClickStaking}>
          <Shared.Text type="extrabold" level={1} style={{ color: 'white' }}>
            Start Staking
          </Shared.Text>
        </Shared.Button>
      </BoxWrap>

      <BoxWrap>
        {BoxArr.map((item, index) => (
          <Shared.GrayBox key={index} style={{ textAlign: 'center' }}>
            <Shared.Text type="extrabold" level={1}>
              {item.title}
            </Shared.Text>
            <Shared.Text type="regular" level={2}>
              {item.desc}
            </Shared.Text>
          </Shared.GrayBox>
        ))}
      </BoxWrap>
    </SectionWrap>
  )
}
const SectionWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: column-reverse;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: column-reverse;
  }
`
const BoxWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    // GrayBox
    div:not(:last-child) {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
    margin-bottom: 100px;
    // GrayBox
    div:not(:last-child) {
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
`

export default Section2
