import React from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import MMVG from '../index.json'
// TODO: Combine the ContractInfo of the classes ContractInfo
import { ContractInfo } from './controller'

interface StakingSection1Props {
  contractInfos: ContractInfo[]
}

const Section2 = (props: StakingSection1Props) => {
  return (
    <StyledSection2>
      <DashboardContainer>
        <DashboardDesc>
          <Shared.MMVGIcon.SmallNFTCard />
          <div style={{ marginLeft: '30px' }}>
            <Shared.Text type="extrabold" level={2} style={{ color: 'white' }}>
              NFT holder staking
            </Shared.Text>
            <Shared.Text type="regular" level={3} style={{ maxWidth: '486px' }}>
              {MMVG.staking.Holder}
            </Shared.Text>
          </div>
        </DashboardDesc>
        <DashboardInfo>
          <LogoWrap>
            <Shared.MMVGIcon.OpenseaLogoImg />
            <Shared.MMVGIcon.SandboxLogoImg />
          </LogoWrap>
        </DashboardInfo>
      </DashboardContainer>
      <Line />
    </StyledSection2>
  )
}

export default Section2

const StyledSection2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
    align-items: flex-end;
  }
`

const LogoWrap = styled.div`
  margin-top: 25px;
  img:first-child {
    margin-right: 20px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 0;
  border: 1px solid #707070;
  margin: 50px;
`

const DashboardDesc = styled.div`
  display: flex;
  padding-left: 0;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    img:first-child {
      display: block;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    img:first-child {
      display: none;
    }
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-left: 81px;
    img:first-child {
      display: block;
    }
  }
`

const DashboardInfo = styled.div`
  display: flex;
  padding-right: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    padding-right: 81px;
  }
`
