import axios from '../axios'
import { Total } from './type'

export const GetTotalData = async () => {
  try {
    const res = await axios.get<Total>('/pool/supply')
    return res.data
  } catch (error) {
    console.error('Error fetching pool data: ', error)
    throw error
  }
}
