import React from 'react'
import Dashboard from './section1'
import Section2 from './section2'

// TODO: Combine the ContractInfo of the classes ContractInfo
import { ContractInfo, ContractSummary, MyStakingContractInfo, MyStakingContractSummary } from './controller'
import * as Shared from '../../components/Shared'
import styled from 'styled-components'

export interface DashboardViewProps {
  contractInfos: ContractInfo[]
  contractSummary: ContractSummary
  myStakingContractSummary: MyStakingContractSummary
}

const StakingView = (props: DashboardViewProps) => {
  const { contractInfos, contractSummary, myStakingContractSummary } = props
  const notNftContractInfos = contractInfos.filter((contractInfo) => contractInfo.pool.nftlist.length === 0)
  const nftContractInfos = contractInfos.filter((contractInfo) => contractInfo.pool.nftlist.length > 0)

  return (
    <>
      {contractInfos && contractInfos.length > 0 && (
        <>
          <Dashboard myStakingContractSummary={myStakingContractSummary} />
          <StakingCardContainer>
            {notNftContractInfos &&
              notNftContractInfos.map((data: ContractInfo) => {
                return <Shared.StakingCard contractInfo={data} />
              })}
          </StakingCardContainer>
          <Section2 contractInfos={nftContractInfos} />
          <StakingCardContainer>
            {nftContractInfos &&
              nftContractInfos.map((data: ContractInfo) => {
                return <Shared.StakingCard contractInfo={data} />
              })}
          </StakingCardContainer>
        </>
      )}
    </>
  )
}

export default StakingView

const StakingCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 200px;
`
