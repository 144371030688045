import React, { MutableRefObject, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { connectWallet } from '../../service/web3'
import Logo from '../../assets/image/logo.png'
import * as Shared from '../Shared'
import { useNavigate } from 'react-router-dom'
import { Wallet } from '../../classes'
import { WalletContext } from '../../contexts/WalletContext'
import Web3 from 'web3'
import MobileBg from '../../assets/image/mobile_bg.png'

interface StyledHeaderProps {
  isScrolled?: boolean
}

interface ContainerProps {
  image?: string
  width?: number
}

const Header = (props: { headerRef: MutableRefObject<HTMLElement | null> }) => {
  const { headerRef } = props
  const [showModal, setShowModal] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth)
  const { wallet, setWallet } = useContext(WalletContext)
  const navigate = useNavigate()

  const handleClickConnectWallet = async () => {
    try {
      const [web3, account] = await connectWallet()
      if (web3 == null || account == null) {
        return navigate('/')
      }
      setWallet(new Wallet({ web3, account }))
      await navigate('/staking')
      await setShowModal(false)
    } catch (error: unknown) {
      if (error instanceof Error) {
        alert(error.message)
      } else {
        alert('Please try again after connecting your wallet.')
      }
      navigate('/')
    }
  }

  useEffect(() => {
    const checkWalletConnection = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' })

        if (accounts.length) {
          const web3 = new Web3(window.ethereum)
          setWallet(new Wallet({ web3, account: accounts[0] }))
        }
      }
    }
    checkWalletConnection()
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    }
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      setCurrentWidth(window.innerWidth)
    }
  }, [])

  const handleClickHambuger = () => {
    setShowModal(true)
  }
  const handleClickClose = () => {
    setShowModal(false)
  }
  const handleClickLogo = () => {
    navigate('/')
  }

  const linkImageArr = [
    { image: <Shared.MMVGIcon.XImg />, link: 'https://twitter.com/memevengers1963' },
    { image: <Shared.MMVGIcon.TelegramImg />, link: 'https://t.me/MMVG_Official' },
    { image: <Shared.MMVGIcon.GithubImg />, link: 'https://github.com/memevengers/MEMEVENGERS' },
    { image: <Shared.MMVGIcon.MediumImg />, link: 'https://medium.com/@marketing_23642/mmvgs-tokenomics-8f89f9c8e370' }
  ]

  return (
    <>
      {isMobile ? (
        <Nav.HeaderWrap>
          <Nav.StyledHeader isScrolled={isScrolled} ref={headerRef} style={{ flexDirection: 'row' }}>
            <Nav.LogoWrap src={Logo} alt="logo" onClick={handleClickLogo} style={{ width: '100px' }} />
            <Shared.MMVGIcon.HambugerImg onClick={handleClickHambuger} />
          </Nav.StyledHeader>
        </Nav.HeaderWrap>
      ) : (
        <Nav.HeaderWrap>
          <Nav.StyledHeader isScrolled={isScrolled} ref={headerRef}>
            <Nav.LogoWrap src={Logo} alt="logo" onClick={handleClickLogo} />
            <Shared.Button type="double" onClick={() => navigate('/staking')} active={true}>
              <Shared.Text type="extrabold" level={1} style={{ color: 'white' }}>
                Pools List
              </Shared.Text>
              <Shared.Button type="navsmall" onClick={handleClickConnectWallet} active={true}>
                <Shared.Text type="extrabold" level={1} style={{ color: 'white' }}>
                  {!wallet ? 'Wallet Connect' : `${wallet.account.slice(0, 6)}...${wallet.account.slice(-5)}`}
                </Shared.Text>
              </Shared.Button>
            </Shared.Button>
          </Nav.StyledHeader>
        </Nav.HeaderWrap>
      )}
      {showModal && (
        <Nav.ModalContainer onClick={() => setShowModal(false)} image={MobileBg}>
          <Nav.ModalWrap image={MobileBg} width={currentWidth}>
            <Nav.ModalHeader>
              <Shared.MMVGIcon.CloseImg onClick={handleClickClose} />
            </Nav.ModalHeader>
            <Nav.ModalContent>
              <Shared.MMVGIcon.BigLogo onClick={handleClickLogo} />
              <Nav.ModalButtonWrap>
                <Shared.Button
                  type="unfilled"
                  onClick={() => navigate('/staking')}
                  active={true}
                  style={{ width: '200px', height: '30px' }}
                >
                  <Shared.Text type="extrabold" level={1} style={{ color: 'white' }}>
                    Pools List
                  </Shared.Text>
                </Shared.Button>
                <Shared.Button
                  type="big"
                  onClick={handleClickConnectWallet}
                  active={true}
                  style={{ width: '200px', height: '30px' }}
                >
                  <Shared.Text type="extrabold" level={1} style={{ color: 'white' }}>
                    {!wallet ? 'Wallet Connect' : `${wallet.account.slice(0, 6)}...${wallet.account.slice(-5)}`}
                  </Shared.Text>
                </Shared.Button>
              </Nav.ModalButtonWrap>
              <Nav.ModalLogosWrap>
                {linkImageArr.map((item, index) => (
                  <NewTabLink key={index} href={item.link} target="_blank">
                    {item.image}
                  </NewTabLink>
                ))}
              </Nav.ModalLogosWrap>
            </Nav.ModalContent>
          </Nav.ModalWrap>
          <Nav.ModalBlack />
        </Nav.ModalContainer>
      )}
    </>
  )
}

const Nav = {
  HeaderSticky: styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    z-index: 1;
  `,
  HeaderWrap: styled.div`
    position: relative;
    width: 100%;
  `,
  // StyledHeader: styled.header<StyledHeaderProps>`
  //   background-color: ${(props) => (props.isScrolled ? 'black' : 'transparent')};
  //   position: fixed;
  //   left: 0; // 왼쪽 끝에서 시작하게 설정
  //   right: 0; // 오른쪽 끝에서 끝나게 설정
  //   transition: background-color 0.5s ease;
  //   width: 100vw;
  //   min-height: 30px;
  //   padding: 30px 0;
  //   color: white;
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: space-between;
  // `,
  StyledHeader: styled.header<StyledHeaderProps>`
    width: 100%;
    min-height: 30px;
    padding: 30px 0;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `,

  LogoWrap: styled.img`
    cursor: pointer;
    width: 100px;
    height: 31.5px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      width: 200px;
      height: 63px;
    }
  `,
  ModalContainer: styled.div<ContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    background-image: ${(props) => `url(${props.image})`};
    background-size: contain;
    background-repeat: no-repeat;
  `,
  ModalWrap: styled.div<ContainerProps>`
    position: relative;
    width: 100%;
    min-width: ${(props) => `${props.width}px`};
    background-color: black;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 5px 6px 0 #f4ad48;
    border-radius: 20px;
  `,
  ModalBlack: styled.div`
    width: 100%;
    height: 60vh;
    background-color: balck;
  `,
  ModalHeader: styled.header`
    position: absolute;
    width: 360px;
    min-height: 30px;
    padding: 30px;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  `,
  ModalContent: styled.div`
    position: relative;
    padding: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  `,
  ModalButtonWrap: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    & > :first-child {
      margin-bottom: 20px;
    }
  `,
  ModalLogosWrap: styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 60px 0 30px 0;
    gap: 40px;
  `
}

const NewTabLink = styled.a`
  decoration: none;
  color: white;
  &:hover {
    color: white;
    pointer: cursor;
  }
`

export default Header
