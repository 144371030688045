import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import * as Shared from '../Shared'
import Close from '../../assets/image/close.svg'

// interface NoticeDropDownProps {
//   infoName: string
//   openInfo: string[]
//   setOpenInfo: (openInfo: string[]) => void
//   children: React.ReactNode
// }

interface NoticeDropDownProps {
  desc: string
}

const InfoDropDown = (props: NoticeDropDownProps) => {
  // const { children, infoName, openInfo, setOpenInfo } = props
  const { desc } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)

  const handlCloseBtn = () => {
    setIsOpen(false)
  }

  const handleClickInfo = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    const updateViewport = () => {
      setIsMobile(window.innerWidth < 768)
      setIsTablet(window.innerWidth >= 768 && window.innerWidth < 1366)
      setIsDesktop(window.innerWidth >= 1366)
    }

    updateViewport()
    window.addEventListener('resize', updateViewport)

    return () => {
      window.removeEventListener('resize', updateViewport)
    }
  }, [])

  return (
    <Icon.Container
      onMouseEnter={isDesktop ? handleClickInfo : undefined}
      onMouseLeave={isDesktop ? handlCloseBtn : undefined}
      // 최상위 돔에 이벤트 추가
    >
      <Shared.MMVGIcon.InfoImg onClick={isMobile || isTablet ? handleClickInfo : undefined} />
      {isOpen && (
        <Info.Box>
          <Info.Container>
            {/* <Info.CloseBtn src={Close} alt="close" onClick={handlCloseBtn} /> */}
            {/* close btn 임시 삭제 */}
            <Info.Content>
              <Shared.Text type="regular" level={isMobile ? 3 : 1}>
                {desc}
              </Shared.Text>
            </Info.Content>
          </Info.Container>
        </Info.Box>
      )}
    </Icon.Container>
  )
}

export default InfoDropDown

const Icon = {
  Container: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  `
}
// mobile 1313 tablet 1010 desktop 1515
const Info = {
  Box: styled.div`
    position: absolute;
    top: 15px;
    right: 70px;
    z-index: 100;
    width: 100%;
    height: 100%;
    max-width: 160px;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      top: 0px;
      right: -25px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      top: 0px;
      right: -30px;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    width: 131px;
    margin-bottom: 20px;
    background-color: #434343;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      padding: 20px;
      width: 131px;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      padding: 10px;
      width: 120px;
    }
  `,
  Content: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
      width: 100%;
      height: 100%;
    }
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      width: 100%;
      height: 100%;
    }
  `,
  CloseBtn: styled.img`
    width: 10px;
    height: 10px;
    margin-left: auto;
    @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
      display: none;
    }
  `
}
