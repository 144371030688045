import React from 'react'
import styled from 'styled-components'

interface TextProps {
  children: React.ReactNode
  type: 'light' | 'regular' | 'semibold' | 'bold' | 'extrabold'
  level: 1 | 2 | 3
  style?: React.CSSProperties
}
interface TextStyleProps {
  level: 1 | 2 | 3
}

const Text = (props: TextProps) => {
  const { children, type, level, style } = props
  switch (type) {
    case 'light':
      return (
        <Light level={level} style={style}>
          {children}
        </Light>
      )
    case 'regular':
      return (
        <Regular level={level} style={style}>
          {children}
        </Regular>
      )
    case 'semibold':
      return (
        <Semibold level={level} style={style}>
          {children}
        </Semibold>
      )
    case 'bold':
      return (
        <Bold level={level} style={style}>
          {children}
        </Bold>
      )
    case 'extrabold':
      return (
        <ExtraBold level={level} style={style}>
          {children}
        </ExtraBold>
      )
    default:
      return (
        <BaseText level={level} style={style}>
          {children}
        </BaseText>
      )
  }
}

const BaseText = styled.p<TextStyleProps>`
  white-space: pre-line;
  margin: 2.5px;
  line-height: ${(props) => (props.level === 1 ? '1.4' : props.level === 2 ? '1.42' : '1.5')};
`

const Light = styled(BaseText)`
  font-weight: 300;
  font-size: ${(props) => (props.level === 1 ? '8px' : props.level === 2 ? '12px' : '14px')};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => (props.level === 1 ? '10px' : props.level === 2 ? '12px' : '14px')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: ${(props) => (props.level === 1 ? '16px' : props.level === 2 ? '18px' : '24px')};
  }
`

const Regular = styled(BaseText)`
  font-weight: 400;
  font-size: ${(props) => (props.level === 1 ? '8px' : props.level === 2 ? '10px' : '12px')};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => (props.level === 1 ? '12px' : props.level === 2 ? '14px' : '17px')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: ${(props) => (props.level === 1 ? '12px' : props.level === 2 ? '14px' : '18px')};
  }
`

const Semibold = styled(BaseText)`
  font-weight: 600;
  font-size: ${(props) => (props.level === 1 ? '10px' : '12px')};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => (props.level === 1 ? '12px' : '16px')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: ${(props) => (props.level === 1 ? '16px' : '24px')};
  }
`

const Bold = styled(BaseText)`
  font-weight: 700;
  font-size: ${(props) => (props.level === 1 ? '12px' : props.level === 2 ? '14px' : '18px')};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => (props.level === 1 ? '12px' : props.level === 2 ? '14px' : '20px')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: ${(props) => (props.level === 1 ? '16px' : props.level === 2 ? '20px' : '30px')};
  }
`

const ExtraBold = styled(BaseText)`
  color: ${(props) => props.theme.ThemeColor.brand};
  font-weight: 800;
  font-size: ${(props) => (props.level === 1 ? '13px' : '20px')};

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    font-size: ${(props) => (props.level === 1 ? '13px' : '20px')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: ${(props) => (props.level === 1 ? '18px' : '28px')};
  }
`

// // light
// mobile 8, 12, 14
// tablet 12, 14, 16
// desktop 16, 18, 24

// // semibold
// mobile 10, 12
// tablet 12, 18
// desktop 18, 28

// // regular ( medium )
// mobile 8, 10, 12
// tablet 8, 10, 12
// desktop 12, 14, 18

// // extrabold
// mobile 13, 20
// tablet 12, 20
// desktop 20, 28

// //bold
// mobile 12, 14, 18
// tablet 12, 14, 20
// desktop 16, 20, 30

export default Text
