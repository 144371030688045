import axios from '../axios'
import { Daum } from './type'

export const GetPoolData = async () => {
  try {
    const res = await axios.get<Daum[]>('/pool')
    return res.data
  } catch (error) {
    console.error('Error fetching pool data: ', error)
    throw error
  }
}
