import React from 'react'
import styled from 'styled-components'
import * as Shared from '../Shared'

const Footer = () => {
  const linkImageArr = [
    { image: <Shared.MMVGIcon.XImg />, link: 'https://twitter.com/memevengers1963' },
    { image: <Shared.MMVGIcon.TelegramImg />, link: 'https://t.me/MMVG_Official' },
    { image: <Shared.MMVGIcon.GithubImg />, link: 'https://github.com/memevengers/MEMEVENGERS' },
    { image: <Shared.MMVGIcon.MediumImg />, link: 'https://medium.com/@marketing_23642/mmvgs-tokenomics-8f89f9c8e370' }
  ]
  return (
    <StyledFooter>
      <Shared.Text type="regular" level={2}>
        Ⓒ 2023 by MEMEVENGERS. All rights reserved!
      </Shared.Text>
      <LogoWrap>
        {linkImageArr.map((item, index) => (
          <NewTabLink key={index} href={item.link} target="_blank">
            {item.image}
          </NewTabLink>
        ))}
      </LogoWrap>
    </StyledFooter>
  )
}

export default Footer

const StyledFooter = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    justify-content: space-between;
  }
`

const NewTabLink = styled.a`
  decoration: none;
  color: white;
  &:hover {
    color: white;
    pointer: cursor;
  }
`

const LogoWrap = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    display: flex;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: flex;
  }
`
