export const handleShareClick = async (id: number | null = null) => {
  try {
    let currentUrl = window.location.href

    if (id !== null) {
      currentUrl += `/${id}`
    }

    await navigator.clipboard.writeText(currentUrl)
    alert('URL has been copied to clipboard!')
  } catch (error) {
    console.error('Copy failed:', error)
    alert('Failed to copy the URL.')
  }
}

export const handleTelegramClick = async (url: string, text: string) => {
  window.open(`https://telegram.me/share/url?url=${url}&text=${text}`, 'popup제목', 'width=400, height=400')
}

export const handelXwitterClick = async (url: string, text: string) => {
  window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`, 'popup제목', 'width=400, height=400')
}
