import React, { useState } from 'react'
import styled from 'styled-components'
import * as Shared from '../../components/Shared'
import MMVG from '../index.json'
import { ThemeColor } from 'src/theme/color'
// TODO: Combine the ContractInfo of the classes ContractInfo
import { ContractSummary, MyStakingContractSummary } from './controller'
import { InfoStyle } from 'src/theme/style'
import { ToLocaleWithFixed, ToMilions } from 'src/utils/number'

interface Section1Props {
  myStakingContractSummary: MyStakingContractSummary
}

const Section1 = (props: Section1Props) => {
  const { myStakingContractSummary } = props

  return (
    <StyledSection1>
      <DashboardContainer>
        <DashboardDesc>
          <Shared.Text type="extrabold" level={2} style={{ color: 'white' }}>
            Dashboard
          </Shared.Text>
          <Shared.Text type="regular" level={3}>
            {MMVG.staking.Dashoboard}
          </Shared.Text>
        </DashboardDesc>
        <DashboardInfo>
          <EmptyGrayBoxOuter>
            <Shared.EmptyGrayBox style={InfoStyle}>
              <Shared.Text type="light" level={2} style={{ display: 'flex' }}>
                Total Deposit
                <Shared.InfoDropDown desc={MMVG.info.deposit} />
              </Shared.Text>
              <Shared.Text type="bold" level={2} style={{ color: ThemeColor.brand }}>
                {ToMilions(myStakingContractSummary.totalStakedTokenCount)} MMVG
              </Shared.Text>
              <Shared.Text type="regular" level={2}>
                ${ToLocaleWithFixed(myStakingContractSummary.totalStakedTokenPrice)}
              </Shared.Text>
            </Shared.EmptyGrayBox>
          </EmptyGrayBoxOuter>
          <EmptyGrayBoxOuter>
            <Shared.EmptyGrayBox style={InfoStyle}>
              <Shared.Text type="light" level={2} style={{ display: 'flex' }}>
                Unclamed Rewards
                <Shared.InfoDropDown desc={MMVG.info.rewards} />
              </Shared.Text>
              <Shared.Text type="bold" level={2} style={{ color: ThemeColor.brand }}>
                {ToMilions(myStakingContractSummary.totalUnclaimedRewardTokenCount)} MMVG
              </Shared.Text>
              <Shared.Text type="regular" level={2}>
                ${ToLocaleWithFixed(myStakingContractSummary.totalUnclaimedRewardTokenPrice)}
              </Shared.Text>
            </Shared.EmptyGrayBox>
          </EmptyGrayBoxOuter>
        </DashboardInfo>
      </DashboardContainer>
      <Line />
    </StyledSection1>
  )
}

export default Section1

const StyledSection1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
  }
`

const DashboardDesc = styled.div`
  text-align: left;
  padding-left: 0;
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    margin-bottom: 9px;
    margin-right: 0;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    margin-bottom: 0;
    margin-right: 9px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-bottom: 0;
    margin-right: 0;
    padding-left: 81px;
  }
`

const EmptyGrayBoxOuter = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 9px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    &:not(:last-child) {
      margin-right: 9px;
      margin-bottom: 0;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    &:not(:last-child) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`

const DashboardInfo = styled.div`
  display: flex;
  padding-right: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.tablet}px) {
    flex-direction: row;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;
    padding-right: 81px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 0;
  border: 1px solid #707070;
  margin: 50px;
`
